import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import HeaderAppBar from '../../header';
import { getGeneralSettings, saveSettings } from '../../../util/Config';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

export default function GeneralSettings() {
    const [empresasAdicionadas, setEmpresasAdicionadas] = useState(getGeneralSettings()?.general.empresasAdicionadas || []);
    const [empresaToken, setEmpresaToken] = useState("");
    const [empresaName, setEmpresaName] = useState("");
    const [settings, setSettings] = useState(getGeneralSettings());
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const handleChangeFields = (event) => {
        const { name, type, value, checked } = event.target;
        const updatedValue = type === 'checkbox' ? checked : value;

        const general = { ...settings.general, [name]: updatedValue };
        setSettings({ ...settings, general });
    }

    const handleSalve = () => {
        saveSettings(settings);
        enqueueSnackbar("Configurações salvas", { variant: "success" });
        history.push('/home');
    }

    const handleAddTokenEmpresa = () => {
        if (empresaToken?.trim().length !== 36) {
            enqueueSnackbar("O token da empresa deve ter 36 dígitos", { variant: "error" });
            return;
        }

        const newEmpresa = { name: empresaName, token: empresaToken.trim() };
        if (empresasAdicionadas.some(e => e.token === newEmpresa.token)) {
            enqueueSnackbar("Esta empresa já foi adicionada", { variant: "warning" });
            return;
        }

        setEmpresasAdicionadas([...empresasAdicionadas, newEmpresa]);
        setEmpresaName("");
        setEmpresaToken("");
    }

    useEffect(() => {
        const general = { ...settings.general, empresasAdicionadas };
        setSettings({ ...settings, general });
    }, [empresasAdicionadas]);

    const handleDeleteEmpresa = (empresa) => {
        setEmpresasAdicionadas(empresasAdicionadas.filter(e => e.token !== empresa.token));
    }

    return (
        <React.Fragment>
            <HeaderAppBar />
            <Container maxWidth="sm">
                <Box pt={10}>
                    <Typography variant="h4" component="h1">Configurações gerais</Typography>
                </Box>

                <Box pt={5} pb={5}>
                    <Grid container spacing={2} direction='column'>
                        <Grid item xs={12}>
                            <TextField
                                label="Tempo para buscar"
                                name="poolingTime"
                                variant="outlined"
                                fullWidth
                                value={settings?.general?.poolingTime}
                                type="number"
                                onChange={handleChangeFields}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Segundos</InputAdornment>,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Tempo para imprimir"
                                name="timeToPrint"
                                variant="outlined"
                                fullWidth
                                value={settings?.general?.timeToPrint}
                                type="number"
                                onChange={handleChangeFields}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Segundos</InputAdornment>,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Switch size="small" name='sound' checked={settings?.general?.sound} onChange={handleChangeFields} />}
                                label="Ativar som para pedidos"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Switch name='socket' size="small" checked={settings?.general?.socket} onChange={handleChangeFields} />}
                                label="Receber os pedidos por websocket"
                            />
                        </Grid>

                        <Grid xs={12} item>
                            <TextField
                                id="timeBeforePrint"
                                label="Antecedência para impressão do pedido"
                                name="timeBeforePrint"
                                variant="outlined"
                                fullWidth
                                value={settings?.general?.timeBeforePrint}
                                type="number"
                                onChange={handleChangeFields}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Minutos</InputAdornment>,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Outras empresas</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item xs={5}>
                                                    <TextField
                                                        label="Nome da empresa"
                                                        value={empresaName}
                                                        onChange={(e) => setEmpresaName(e.target.value)}
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        label="Token da empresa"
                                                        value={empresaToken}
                                                        onChange={(e) => setEmpresaToken(e.target.value)}
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <IconButton
                                                        size="small"
                                                        title="Adicionar empresa"
                                                        onClick={handleAddTokenEmpresa}
                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item style={{ marginTop: '8px' }}>
                                            {empresasAdicionadas.map((empresa, index) => (
                                                <Grid key={index} container spacing={2} alignItems="center">
                                                    <Grid item xs={5}>
                                                        <TextField
                                                            label="Nome da empresa"
                                                            value={empresa.name}
                                                            variant="outlined"
                                                            fullWidth
                                                            InputProps={{ readOnly: true }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="Token da empresa"
                                                            value={empresa.token}
                                                            variant="outlined"
                                                            fullWidth
                                                            InputProps={{ readOnly: true }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <IconButton
                                                            size="small"
                                                            title="Excluir empresa"
                                                            onClick={() => handleDeleteEmpresa(empresa)}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                    </Grid>
                </Box>

                <Button variant="contained" color="primary" onClick={handleSalve}>
                    Salvar configurações
                </Button>
            </Container>
        </React.Fragment>
    );
}
