import { useSnackbar } from 'notistack';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AuthService from '../../AuthService';
import SocketIO from '../../socket';
import { getGeneralSettings } from '../../util/Config';

export default function PrintSocket({onConnection}) {
  const uuid = useRef(localStorage.getItem("_UUID") || '*UUID NÃO CONFIGURADO*')
  const { enqueueSnackbar } = useSnackbar();
  let impressoesSocket = useRef([])
  const dispatch = useDispatch()
  const printLocations = getGeneralSettings().printLocations;
  const service = new AuthService()
  const accessKey = localStorage.getItem("_accessKey");
  let savedPrintPendind = useRef()

  impressoesSocket.current = useSelector(state => state.impressoes)


  function buscarImpressoesPendentes() {
    service.get(`/api/impressao/pooling/${accessKey}`).then(impressoesResp => {
      // console.log('buscarImpressoesPendentes', impressoesResp)
      addImpressao(impressoesResp)

      if(!!getGeneralSettings()?.general.empresasAdicionadas && getGeneralSettings().general.empresasAdicionadas.length > 0){
        getGeneralSettings().general.empresasAdicionadas.map((empresa)=>{
          service.get(`/api/impressao/pooling/${empresa.token}`).then(impressoesRespExtr => {
            // console.log('buscarImpressoesPendentes', impressoesRespExtr)
            addImpressao(impressoesRespExtr)
          }).catch(e => {
            // console.log("Erro buscar pedidos pendentes", e)
            enqueueSnackbar(`Erro buscar pedidos pendentes. Detalhe: ${e}`, { variant: 'error' });
          })
          return null;
        })
      }

    }).catch(e => {
      console.log("Erro buscar pedidos pendentes", e)
      enqueueSnackbar(`Erro buscar pedidos pendentes. Detalhe: ${e}`, { variant: 'error' });
    })
  }

  const addEvents = (socket) => {

    socket.on("connect", () => {
      // console.log('connect SocketIO no PrintOrder', socket.id);

      savedPrintPendind.current = JSON.parse(localStorage.getItem('PRINT_PENDING'))
      // console.log('savedPrintPendind', savedPrintPendind.current)

      if (savedPrintPendind.current && savedPrintPendind.current.length > 0) {
        savedPrintPendind.current.map(e => marcarImpressoesComoImpresso(e.impressao))
      } 

      buscarImpressoesPendentes()

      if (onConnection){
        onConnection()
      }
    });

    socket.on('createImpressao', (impressaoList) => {
      console.log('createImpressao SocketIO ', impressaoList)
      // console.log('printLocations', printLocations)

      addImpressao(impressaoList);
    })
  }

  function marcarImpressoesComoImpresso(documento) {

    console.log('marcarImpressoesComoImpresso', documento)
    const json = { "statusImpressao": "IMPRESSO" }

    service.put(`/api/impressao/update/${documento._id}`, json).then(retornoDocumento => {
      retornoDocumento.impresso = true
      retornoDocumento.impressoAPI = true

      // console.log('savedPrintPendindCopy', retornoDocumento)

      dispatch({ type: 'UPDATE_IMPRESSAO_PENDING', impressao: retornoDocumento })

      enqueueSnackbar('Impressão marcadas como impresso', { variant: 'success' })
      console.log("Impressão marcadas como impresso", retornoDocumento)
    }).catch(err => {
      console.error("Erro ao notificar pedido como impresso", documento, err)
      enqueueSnackbar('Erro ao notificar pedido como impresso. Veja o log.', { variant: 'error' })
    })
  }

  function addImpressao(impressaoList) {
    let newImpressoesSocket = [];

    impressaoList.map((impressao) => {

      const tipoConteudo = impressao.conteudo.tipo

      console.log('tipoConteudo', tipoConteudo)

      if (tipoConteudo === 'JSON') {
        const lineUuid = {
          type: 'text',
          value: uuid.current,
          style: `text-align:center;`,
          css: { "font-size": "8px" }
        };

        impressao.conteudo.linhas.push(lineUuid)
      } else if (tipoConteudo === 'URL') {

      } else {
        const lineUuid = {
          texto: uuid.current,
          alinhamento: 'C',
        };

        impressao.conteudo.linhas.push(lineUuid)
      }

      const printLocation = printLocations.find(elem => elem.chave === impressao.localImpressao);

      // console.log('configImpressora', printLocation);

      if (!printLocation) {

        enqueueSnackbar(`Local de impressão ${impressao.localImpressao} não cadastrado.`, { variant: 'error' });
        console.error(`Local de impressão ${impressao.localImpressao} não cadastrado.`);

      } else {

        printLocation.Printers.map(printer => {

          const qtdImpressoes = parseInt(printer.qtdImpressoes) || 1;

          for (var i = 0; i < qtdImpressoes; i++) {

            // console.log('qtdImpressoes', i);

            newImpressoesSocket.push({
              impressao,
              impressora: printer,
              configuracao: printLocation,
              indexImpressao: i
            });
          }
          return null;
        });
      }
      return null;
    });

    dispatch({ type: 'ADD_IMPRESSAO', impressoes: newImpressoesSocket });
  }

  return (
    <div>
      <SocketIO addEvents={addEvents} url={process.env.REACT_APP_API_URL} />
    </div>
  );
}