
const GENERAL_SETTINGS_KEY = 'GENERAL_SETTINGS_KEY'

const IGNORED_DOC_KEY = 'IGNORED_DOC_KEY'

export function getGeneralSettings() {

    // {"locaisImpressao":[{"chave":"CAIXA","localObj":{"_id":"62a9cd814e869e47cb6d2d29","empresaUUID":"36fbba07-dc59-46e6-8648-a555c948d41e","descricao":"CAIXA","chave":"CAIXA","__v":0},"Printers":[{"PrinterName":"Print iD 2","qtdImpressoes":"10"}],"QuantityColumns":40,"FontSize":8},{"chave":"NZ","localObj":{"_id":"62a9cd984e869e47cb6d2d2b","empresaUUID":"36fbba07-dc59-46e6-8648-a555c948d41e","descricao":"COZINHA","chave":"NZ","__v":0},"Printers":[{"PrinterName":"Print iD","qtdImpressoes":"10"}],"QuantityColumns":40,"FontSize":8},{"chave":"NC","localObj":{"_id":"63cd84d2e5fe5772d9d4a2e0","empresaUUID":"36fbba07-dc59-46e6-8648-a555c948d41e","descricao":"BAR","chave":"NC","__v":0},"Printers":[{"PrinterName":"BAR","qtdImpressoes":"10"}],"QuantityColumns":40,"FontSize":8}],"gerais":{"poolingTime":5,"responsavelImpressao":"Parseint"}}
    // _CONFIGURACAO_IMPRESSORAS_

    const defaultSettings = {
        general: {
            sound: false,
            poolingTime: 5,
            timeToPrint: 1,
            socket: true
        },
        printLocations: [
            {
                chave: '',
                Printers: [{ PrinterName: '', qtdImpressoes: 1 }],
                QuantityColumns: 40,
                localObj: { descricao : '' },
                FontSize: 8,
                ativo: true
            }
        ],
    }

    let savedSettings = JSON.parse(localStorage.getItem(GENERAL_SETTINGS_KEY))

    if (!savedSettings) {

        const savedSettingsOld = JSON.parse(localStorage.getItem('_CONFIGURACAO_IMPRESSORAS_'))

        // console.log('savedSettingsOld', savedSettingsOld)

        if (savedSettingsOld) {
            const svd = {
                general: {
                    sound: localStorage.getItem('_somAtivo') || true,
                    poolingTime: 5,
                    timeToPrint: 1,
                    socket: true
                },
                printLocations: savedSettingsOld.locaisImpressao,
            }

            saveSettings(svd)
            // localStorage.removeItem('_somAtivo')
            // localStorage.removeItem('_CONFIGURACAO_IMPRESSORAS_')
        }
    }

    savedSettings = JSON.parse(localStorage.getItem(GENERAL_SETTINGS_KEY))
    // console.log('savedSettings', savedSettings)

    return savedSettings || defaultSettings
}

export function saveSettings(settings) {
    console.log('saveSettings', settings)


    localStorage.setItem(GENERAL_SETTINGS_KEY, JSON.stringify(settings))


    if (!localStorage.getItem("_UUID")) {
        localStorage.setItem("_UUID", `#${new Date().getTime()}`);
      }
}

export function createLocal(local) {

    // console.log('createLocal', local)
    return {
        chave: local.chave,
        localObj: local,
        Printers: [{ PrinterName: '', qtdImpressoes: local.qtdImpressoes || 1 }],
        QuantityColumns: 40,
        FontSize: 8,
    }
}

export function addLocal(locais, local) {
    locais.push({
        chave: local.chave,
        localObj: local,
        Printers: [{ PrinterName: "", qtdImpressoes: local.qtdImpressoes }],
        QuantityColumns: 40,
        FontSize: 8,
    })
}

export function isPrintEnabled(printLocations) {
    return  printLocations?.ativo ?? true; 
}

export function isChaveAtiva(key) {
    // Recupera o JSON do localStorage
    const data = getGeneralSettings()
    
    // Verifica se o dado é válido
    if (!data || !data.printLocations) {
        console.error('Dado inválido ou printLocations não encontrado - não foi possível verificar se chave ativa');
        return false;
    }

    // Busca a localização pela chave fornecida
    const location = data.printLocations.find(location => location.chave === key);

    // Retorna se a localização está ativa ou não (padrão true se o campo "ativo" não existir)
    return location ? (location.ativo ?? true) : false;
}
