
export const initialImpressoes = () => {
    // console.log('initialImpressoes')

    return []
}


export const updateImpressao = (currentState, action) => {
    console.log('updateImpressao', action.impressao)

    const currentImpressoes = currentState.impressoes

    let currentImpressoesCopy = Object.assign([], currentImpressoes)

    const impressaoUpdated = action.impressao;

    const indexItem = currentImpressoesCopy.findIndex(e => e.impressao._id === impressaoUpdated._id && !e.impressao.impresso)

    console.log('updateImpressao -> indexItem', indexItem)

    if (indexItem >= 0) {
        currentImpressoesCopy[indexItem].impressao = impressaoUpdated
    }

    console.log('updateImpressao -> currentImpressoesCopy', currentImpressoesCopy)

    return {
        ...currentState,
        impressoes: currentImpressoesCopy
    }
}

export const updateImpressaoPendente = (currentState, action) => {
    console.log('updateImpressaoPendente', action.impressao)

    const currentImpressoes = currentState.impressoes
    console.log('updateImpressaoPendente -> currentImpressoes', currentImpressoes)

    let currentImpressoesCopy = Object.assign([], currentImpressoes)

    const impressaoUpdated = action.impressao

    currentImpressoesCopy.map(e => {
        if (e.impressao._id === impressaoUpdated._id) {
            e.impressao.impressoAPI = true
        }
    })

    console.log('updateImpressaoPendente -> currentImpressoesCopy', currentImpressoesCopy)

    return {
        ...currentState,
        impressoes: currentImpressoesCopy
    }
}

export const removeImpressao = (currentState, action) => {
    
    const currentImpressoes = currentState.impressoes;

    const impressaoUpdated = action.impressao;

    console.log('removendo impressao', impressaoUpdated);

    // Filtra a lista removendo o objeto com o mesmo _id
    const updatedImpressoes = currentImpressoes.filter(e => e.impressao._id !== impressaoUpdated._id);

    console.log('removeImpressao -> updatedImpressoes', updatedImpressoes);

    return {
        ...currentState,
        impressoes: updatedImpressoes
    }
}

export const createImpressao = (currentState, action) => {

    const novaImpressoes = action.impressoes
    const currentImpressoes = currentState.impressoes

    // console.log('novaImpressoes', novaImpressoes)
    // console.log('currentImpressoes', currentImpressoes)


    let currentImpressoesCopy = Object.assign([], currentImpressoes)

    // // console.log('currentImpressoesCopy', currentImpressoesCopy)

    if (currentImpressoesCopy.length > 0) {

        novaImpressoes.map(ni => {
            const idx = currentImpressoes.findIndex(ci => ci.impressao._id === ni.impressao._id)

            // console.log('currentImpressoes.map', idx)

            if (idx < 0) {
                currentImpressoesCopy.push(ni)
            }
        })
    } else {
        currentImpressoesCopy = novaImpressoes
    }



    // console.log('addImpressao -> currentImpressoesCopy', currentImpressoesCopy)

    return {
        ...currentState,
        impressoes: currentImpressoesCopy
    }
}

export const clearImpressao = (currentState, action) => {

    const novaImpressoes = action.impressoes

    return {
        ...currentState,
        impressoes: novaImpressoes
    }
}