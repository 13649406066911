import { Table, TableBody, TableRow, Paper, Button, TableCell, TableHead, Typography, Chip } from "@material-ui/core"
import React, { useRef, useState } from "react"
import { useSelector } from "react-redux"
import RemoverItem from "../../remove-item"
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EmptyRow from "../emptyRow";
import { getGeneralSettings, isChaveAtiva } from "../../../util/Config";

export default function PedidosNaoImpresso() {
    const settings = useRef(getGeneralSettings())
    const { timeBeforePrint = 0 } = settings.current.general
    const [open, setOpen] = useState(false)
    const [imprimir, setImprimir] = useState("")

    const impressoes = useSelector(state => state.impressoes)

    const handleClickDelete = (impressao) => {
        setOpen(!open)
        setImprimir(impressao)
    }


    function formatDate(dateStr) {
        try {
            const date = new Date(dateStr)
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            return `${day}/${month} ${hours}:${minutes}`;
        } catch (e) {
            console.error("Erro ao formatar data", dateStr)
        }

        return dateStr;
    }


    const impressoesFiltradas = impressoes.length > 0 && impressoes.filter(e => !e.impressao.impresso)

    return (
        <React.Fragment>
            <Paper >
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell component="th" />
                            <TableCell component="th">#</TableCell>
                            <TableCell component="th">local</TableCell>
                            <TableCell component="th">Data/Hora</TableCell>
                            <TableCell component="th" />

                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {impressoesFiltradas.length > 0 ? impressoesFiltradas.map((impressao) => (
                            <TableRow key={impressao.impressao._id}>
                                <TableCell align="center"> {!impressao.impressora.PrinterName ?
                                    <Tooltip title={`Impressora não informanda para o local: ${impressao.impressao.localImpressao}`}>
                                        <IconButton >
                                            <ErrorIcon color="error" />
                                        </IconButton>
                                    </Tooltip> :
                                    <CheckCircleIcon color="secondary" />}
                                </TableCell>
                                <TableCell>
                                    <Typography>{impressao.impressao.identificador}</Typography>
                                    <Typography variant="caption" color="textSecondary">{impressao.impressao.descricao}</Typography>
                                    
                                    {impressao.impressao.dataPedido && timeBeforePrint > 0 ? (
                                        <Typography variant="caption" color="textSecondary">
                                            {" | "}Impressão prevista para:{" "}
                                            {formatDate(
                                                new Date(
                                                    new Date(impressao.impressao.dataPedido).getTime() -
                                                    timeBeforePrint * 60 * 1000
                                                )
                                            )}
                                        </Typography>
                                    ) : (
                                        <></>
                                        // <Typography variant="caption" color="textSecondary">
                                        //     {" | "}Data de impressão não disponível.
                                        // </Typography>
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Typography>{impressao.impressao.localImpressao}</Typography>
                                    {!isChaveAtiva(impressao.impressao.localImpressao) && (<Chip size="small" label="Inativo" />)}
                                </TableCell>
                                <TableCell scope="row">{formatDate(impressao.impressao.dataCriacao)}</TableCell>
                                <TableCell scope="row">
                                    <IconButton onClick={() => handleClickDelete(impressao.impressao)}>
                                        <MoreVertIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )) :

                            <EmptyRow info="nenhuma impressão pendente no momento" />
                        }

                    </TableBody>
                </Table>
            </Paper>
            <RemoverItem open={open} setOpen={setOpen} impressao={imprimir} />
        </React.Fragment>
    )
}